










































import APISTATE from '@/enums/APISTATE';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'school-terms-view',
  async mounted() {
    this.updatePageTitle('Semestre');

    await this.loadSchoolTermStatusTypes();
    await this.loadSchoolTerms();
  },
  methods: {
    ...mapActions(['updatePageTitle']),
    ...mapActions('enumStore', ['loadSchoolTermStatusTypes']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    onRowClick(row: SchoolTermDto) {
      this.$router.push(`/schoolterm/${row.id}`);
    }
  },
  computed: {
    ...mapGetters('enumStore', ['schoolTermStatusApiState', 'schoolTermStatusTypes', 'schoolTermStatusLabelByName']),
    ...mapGetters('schoolTermStore', ['schoolTermApiState', 'schoolTerms']),
    isLoading(): Boolean {
      return this.schoolTermApiState === APISTATE.LOADING;
    },

  }
})
